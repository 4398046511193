// App Dictionaries

export const profile = {
  'cpf': 'cpf_motorista',
  'partner': 'parceiro',
  'partner_id': 'id_parceiro',
  'fullname': 'nm_motorista',
  'email': 'email_motorista',
  'photo': 'foto_motorista',
  'birthdate': 'dt_nasc_motorista',
  'phone': 'wpp_motorista',
  'gender': 'genero',
  'rg.id': 'rg_motorista',
  'rg.issuer': 'org_exp_motorista',
  'cnh': 'cnh_motorista',
  'password': 'senha',
  'status': 'status',
  'statusChange': 'dt_Status',
  'inactive': 'inatividade',
  'job.products': 'products_interest',
  'job.special_projects': 'special_projects_interest',
  'job.apps': 'app_work',
  'job.apps_percent': 'app_work_percent',
  'job.service_model': 'mod_trab',
  'job.fulltime': 'nivel_env',
  'job.journey': 'nr_rodadas',
  'job.region': 'regiao_rodada',
  'job.rating': 'nota_app',
  'job.experience': 'tempo_motorista',
  'address.country': 'pais',
  'address.state': 'uf',
  'address.city': 'cidade',
  'address.zip': 'cep',
  'address.neighborhood': 'bairro',
  'address.street': 'logradouro',
  'address.number': 'numero',
  'address.compl': 'complemento',
  'vehicle.plate': 'placa',
  'vehicle.chassis': 'chassi',
  'vehicle.brand': 'marca',
  'vehicle.model': 'modelo',
  'vehicle.year': 'ano_fab',
  'vehicle.parking': 'estacionamento',
  'vehicle.fuel': 'fuel_type',
  'vehicle.color': 'cor',
  'vehicle.renavam': 'renavam',
  'vehicle.isOwner': 'dono',
  'vehicle.rental': 'nm_locadora',
  'vehicle.ownerName': 'nm_prop',
  'vehicle.ownerEmail': 'email_prop',
  'vehicle.ownerRg': 'rg_prop',
  'vehicle.ownerOrg_exp': 'org_exp_prop',
  'vehicle.ownerCpf': 'cpf_prop',
  'vehicle.ownerRelative': 'parent_prop',
  'payment.bank': 'id_banco',
  'payment.branch': 'agencia',
  'payment.account': 'conta',
  'payment.digit': 'dv',
  'payment.type': 'tipo_conta',
  'payment.compl': 'comp_conta',
};

export const events = {
  id: 'id_flashmob',
  title: 'nm_flashmob',
  type: 'tipo_flashmob',
  hoload: 'hoload',
  mobilizesAt: 'dt_mobilizacao',
  startsAt: 'dt_inicio',
  endsAt: 'dt_fim',
  demobilizesAt: 'dt_desmobilizacao',
  location: 'local',
  meeting_point: 'ponto_de_encontro',
  obs: 'obs',
  route: 'rota',
  reward: 'remuneracao',
  program: 'fila_forcada',
  drivers: {
    key: 'parceiros',
    acceptedAt: 'dt_aceite',
    declinedAt: 'dt_declinio',
    invitedAt: 'dt_convite',
    uninvitedAt: 'dt_desconvite',
    partner_id: 'id_parceiro',
    id: 'id_motorista',
    fullname: 'nm_motorista',
    cpf: 'cpf_motorista',
    email: 'email_motorista',
    id_device: 'id_device',
    swarm: 'swarm',
    id_flashmob_driver: 'id_flashmob_parceiro'
  },
  FLS: 'Flashmob',
  SWR: 'Swarm',
}

export default {
  profile,
  events
}